import React from "react";
import { embedCheckout } from "@bigcommerce/checkout-sdk";
import getRedirectURL from "./getRedirectURL";

export default class EmbeddedCheckout extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loginUrl: null
    }
  }

  async componentDidMount() {
    const { cartId } = this.props;

    let { url } = await getRedirectURL(cartId);
    console.log(url);
    this.setState({ loginUrl: url });

    try {
      // Replace URL with the Embedded Checkout URL for the cart
      // https://developer.bigcommerce.com/api-reference/store-management/carts/cart-redirect-urls/createcartredirecturl

      embedCheckout({
        containerId: "checkout-container",
        url: url
      });
    } catch(err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div className="container">
        <span>Checkout container</span>
        <div id="checkout-container"></div>
      </div>
    );
  }
}
