const config = {
  method: 'GET',
  mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
};

export default function getRedirectURL(cartid) {
  return new Promise((resolve, reject) => {
    fetch(`https://reactcheckoutserver.ngrok.io/getcarturl/${cartid}`, config)
    .then(response => response.json())
    .then(response => {
      //console.log(response);
      resolve(response);
    })
    .catch(err => {
      console.log(err);
      reject(err);
    });
  });
}