import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import Main from './Main';

window.testFunction = () => {
  console.log("Test function called");
};

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('app-container')
);
